.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  padding: 40px 0;
  background-color: var(--popup-bg) !important;
  z-index: 99999;
  display: flex;

  &.popup__open {
    display: none;
  }

  .container {
    width: 540px;
    height: fit-content;
    background-color: var(--black-white);
    border-radius: 12px;
    padding: 32px 24px 24px 24px;
    margin: auto;
    position: relative;

    .close {
      position: absolute;
      top: 1%;
      right: 2%;
      font-size: 20px;
      color: var(--inherit-color);
      cursor: pointer;
    }

    .main {
      width: 100%;
      color: var(--inherit-color);

      .agency {
        width: 100%;
        align-items: flex-start;

        img {
          min-width: 124px;
          margin-right: 12px;
        }

        .right {
          width: 100%;

          .title {
            font-size: 20px;
            line-height: 24px;
            color: var(--inherit-color);

            &:hover {
              text-decoration: underline;
            }
          }

          .text {
            opacity: 0.8;
            font-weight: 400;
            font-size: 14px;
            line-height: 20.3px;
            margin: 4px 0 8px 0;
          }

          .contacts {
            font-size: 12px;
            line-height: 14.4px;
            display: flex;

            .phone {
              margin-right: 10px;

              &:hover {
                text-decoration: underline;
              }
            }

            a {
              color: var(--inherit-color);

              &:hover {
                text-decoration: underline;
              }
            }

            .agency__webpage {
              color: var(--blue);

              &:hover {
                text-decoration: underline;
              }

              &::after {
                content: url(../../icons/arrow.svg);
                margin-left: 4px;
              }
            }
          }
        }
      }

      .website {
        .website__header {
          .rate {
            width: 24px;
            height: 22px;
            font-family: Inter;
            margin-right: 20px;

            span {
              color: var(--website-rate-count);
              position: relative;

              &::after {
                content: attr(data-rate);
                font-size: 10px;
                font-weight: 600;
                position: absolute;
                top: -30%;
              }

              &.green::after {
                color: var(--green);
              }

              &.red::after {
                color: var(--red);
              }
            }
          }
        }

        .header__right {
          .right__name {
            width: fit-content;
            margin-bottom: 4px;

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }

            a {
              font-size: 20px;
              line-height: 24px;
              color: var(--inherit-color);

              &:hover {
                text-decoration: underline;
              }
            }

          }

          .text {
            color: var(--inherit-color);
            opacity: .8;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .website__webpage {
          color: var(--blue);
          margin-top: 8px;
          font-size: 12px;
          line-height: 14.4px;
          float: right;
          &:hover {
            text-decoration: underline;
          }

          &::after {
            content: url(../../icons/arrow.svg);
            margin-left: 4px;
          }
        }
      }

      .reviews__login {
        width: 100%;
        margin-top: 20px;

        .count {
          width: fit-content;
          flex-wrap: wrap;

          div {
            margin-right: 8px;
          }

          p {
            font-size: 13px;
            line-height: 13px;
            margin-top: 5px;

            span {
              font-weight: 400;
            }
          }
        }

        .login__edit {
          font-family: Inter;
          padding: 6px 8px;
          background-color: var(--website-review-border);
          color: var(--inherit-color);
          font-size: 13px;
          line-height: 13px;
          cursor: pointer;
          transition: .3s;
          margin-left: auto;

          &:hover {
            opacity: .8;
          }
        }
      }

      .review__form {
        width: 100%;
        margin-top: 16px;

        .title {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 12px;
        }

        form {
          width: 100%;

          input,
          textarea {
            width: 100%;
            padding: 14px 16px;
            font-family: Lato;
            background-color: var(--review-bg);
            resize: none;
            margin-bottom: 8px;
            color: var(--inherit-color);

            &::placeholder {
              font-size: 16px;
              line-height: 16px;
              color: var(--review-placeholder);
            }
          }

          textarea {
            min-height: 89px;
          }

          .your__score {
            width: 100%;
            justify-content: flex-start;
            margin-top: 4px;

            p {
              margin-right: 10px;
            }
          }

          button {
            width: 100%;
            cursor: pointer;
            background-color: var(--white-black);
            color: var(--black-white);
            padding: 14px 0;
            margin-top: 12px;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;
            transition: .3s;

            &:hover {
              opacity: .8;
            }
          }
        }
      }

      .reviews {
        // height: calc(100vh - 590px);
        margin-top: 16px;
        overflow-y: auto;
        width: 100%;
        // min-height: 200px;

        .item {
          width: 100%;
          padding: 12px;
          margin-bottom: 10px;
          border: 1px solid var(--review-border);

          &:last-child {
            margin-bottom: 0;
          }

          .header {
            width: 100%;
            align-items: flex-start;

            .person {
              color: var(--review-placeholder);

              .info {
                .date {
                  font-size: 12px;
                  line-height: 13px;
                }

                .name {
                  color: var(--inherit-color);
                  font-size: 16px;
                }
              }
            }
          }

          .text {
            color: var(--inherit-color);
            opacity: .8;
            font-size: 14px;
            font-weight: 400;
            margin-top: 6px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .popup {
    padding: 0;

    .container {
      width: 100%;
      padding: 24px 16px 16px 16px;

      .main {
        .agency {
          flex-direction: column;

          img {
            margin-bottom: 12px;
          }
        }
      }
    }

    .count {
      flex-wrap: wrap;

    }

    .contacts {
      flex-wrap: wrap;

      p,
      a {
        margin: 0 12px 12px 0 !important;
      }
    }
  }
}