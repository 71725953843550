.wrapper {
  width: 100%;
  min-width: 478px;
  max-width: 800px;
  margin-right: 12px;

  .container {
    width: 100%;

    h2 {
      width: 100%;
      background-color: var(--black-white);
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: var(--inherit-color);
      padding: 20px;
      border-bottom: 1px solid var(--inherit-border);
      transition: .3s;
    }

    .websites {
      transition: .3s;
      background-color: var(--black-white);
      width: 100%;
      min-height: 100vh;

      .website {
        width: 100%;
        padding: 20px;

        // .rate {

        .rate {
          width: 24px;
          height: 22px;
          font-family: Inter;
          margin-right: 20px;
          color: var(--website-rate-count);
          position: relative;

          &::after {
            content: attr(data-rate);
            font-size: 10px;
            font-weight: 600;
            position: absolute;
            top: -30%;
          }

          &.green::after {
            color: var(--green);
          }

          &.red::after {
            color: var(--red);
          }

        }

        // }

        .info {
          width: 100%;
          align-items: flex-end;


          .main {
            margin-right: 20px;

            .name {
              width: fit-content;
              margin-bottom: 4px;

              img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }

              a {
                font-size: 16px;
                line-height: 24px;
                color: var(--inherit-color);

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            p {
              color: var(--inherit-color);
              opacity: 0.8;
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 8px;
            }

            .local__link {
              width: fit-content;

              .local {
                padding: 2px 4px 2px 4px;
                color: var(--inherit-color);
                font-size: 12px;
                line-height: 14.4px;
                font-weight: 400;
                background-color: var(--local-bg);
                margin-right: 12px;
                display: flex;
                justify-content: center;
                align-items: center;

                &::before {
                  margin: 2px 4px 0 0;
                  content: url(../../icons/location-icon.svg);
                  filter: var(--local-filter);
                }
              }

              .link {
                color: var(--blue);
                line-height: 14.4px;

                &:hover {
                  text-decoration: underline;
                }

                &::after {
                  content: url(../../icons/arrow.svg);
                  margin-left: 4px;
                }
              }
            }
          }
        }

        .views {
          position: relative;
          width: 100%;
          min-width: 124px;
          max-width: fit-content;
          border: 1px solid var(--border-opacity);
          padding: 12px;
          transition: 0.3s;
          cursor: pointer;

          .pointer {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            z-index: 2;
          }

          &:hover {
            border: 1px solid var(--inherit-color);
          }

          .stars {
            height: 18px;
          }

          p {
            width: fit-content;
            color: var(--inherit-color);
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            margin: 4px auto 0 auto;
            text-align: center;
          }

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            transform: translateY(0) translateX(0);
            top: 110%;
            left: 50%;
            rotate: 45deg;
            border-radius: 1px;
            background-color: var(--white);
            opacity: 0;
            pointer-events: none;
          }

          &::after {
            content: attr(data-toolbar);
            position: absolute;
            transform: translateY(0) translateX(-50%);
            top: 115%;
            left: 50%;
            width: max-content;
            min-width: 77px;
            height: fit-content;
            text-align: center;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 600;
            font-family: Lato;
            line-height: 13.2px;
            background-color: var(--white);
            padding: 4px;
            opacity: 0;
            pointer-events: none;
            color: #000;
          }

          &:hover {
            &::before {
              opacity: 1;
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    .show__more_btn {
      margin: 16px auto 0 auto;
      background-color: var(--show-more-btn);
      padding: 13px 16px;
      line-height: 18px;
      color: var(--white);
      font-size: 15px;
      font-family: Lato;
      font-weight: 500;
      cursor: pointer;
      transition: .3s;

      &:hover {
        opacity: .8;
      }
    }
  }

  .traffic {
    color: var(--inherit-color);
    font-size: 14px;
    font-weight: 700;
    line-height: normal;

    span {
      font-weight: 500;
      opacity: 0.8;
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-right: 0;

    .container {
      h2 {
        display: none;
      }

      .websites {
        .website {
          .info {
            .views {
              &:hover {
                border: 1px solid var(--border-opacity);
              }

              &::before {
                display: none;
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 525px) {
  .wrapper {
    min-width: 100%;

    .container {
      .websites {
        .website {
          .info {
            flex-direction: column !important;
            align-items: flex-start;

            .main {
              margin-right: 0;
              margin-bottom: 12px;
            }

            .views {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &:hover {
                border: 1px solid var(--inherit-color);
              }

              .stars {
                height: 18px;
              }

              p {
                margin: 0 0 0 8px;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}