.toggle__blocks {
    width: 100%;

    .buttons {
        width: 100%;
        position: relative;

        button {
            width: 50%;
            background-color: var(--black-white);
            color: var(--toggle-buttons);
            font-size: 20px;
            line-height: 24px;
            padding: 12px 0 8px 0;
            transition: .3s;
            border-bottom: 2px solid var(--inherit-border);
            cursor: pointer;

            &.active {
                color: var(--inherit-color);
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50%;
            height: 2px;
            background-color: var(--red);
            transition: .3s;
        }
    }

    .buttons.toggled {
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
        }
    }
}

@media (max-width: 430px) {
    .toggle__blocks {
        .buttons {
            button {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}