.wrapper {
    width: 100%;
    max-width: 800px;
    padding: 33px 24px;
    margin-top: 24px;
    background-image: url(../../images/add-agency.webp);
    background-position: center;
    background-size: cover;

    img {
        width: 142px;
    }

    p {
        font-size: 36px;
        line-height: 43.2px;
        color: var(--white);
        margin: 12px 0;
        text-align: center;
    }

    form {
        line-height: 18px;

        input {
            padding: 17px 16px;
            width: 180px;
            margin-right: 4px;

            &::placeholder {
                color: var(--black);
                opacity: .37;
            }
        }

        button {
            background-color: var(--black);
            padding: 17px 21px;
            color: var(--white);
            cursor: pointer;
            transition: .3s;
            
            &:hover {
                background-color: #000;
                // color: var(--black);   
            }
        }
    }
}

@media (max-width: 970px) {
    .wrapper {
        padding: 25px 21px;
    }
}

@media (max-width: 900px) {
    .wrapper {
        p {
            font-size: 28px;
            line-height: 33.6px;
        }
    }
}

@media (max-width: 960px) {
    .wrapper {
        width: 100%;

        p {
            font-size: 20px;
            line-height: 24px;
        }

        form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            input {
                padding: 15px 16px;
                width: 280px;
                margin-right: 0px;

                &:nth-child(2) {
                    margin: 4px 0;
                }
            }
    
            button {
                width: 280px;
                padding: 15px 21px;
            }
        }
    }
}