@import '../variables.scss';

.page.dark, .loading__wrapper.dark {
    --bg-header-add-site-btn: var(--gray);
    --header-opacity: #000000cc;
    --page-bg: url("../../images/dark-bg.webp");
    --inherit-color: var(--white);
    --add-site-link: var(--gray);
    --border-opacity: var(--white-opacity);
    --theme-btn: rgba(255, 255, 255, 0.12);
    --theme-sun: #ffffff66;
    --theme-moon: var(--yellow);
    --theme-bg: 75%;
    --sun-bg: none;
    --moon-bg: var(--white);
    --country-disabled: #141414;
    --country-active: #1f1f1f;
    --main-bg: #111111;
    --black-white: #000;
    --white-black: var(--white);
    --inherit-border: rgba(255, 255, 255, 0.12);
    --website-rate-count: var(--white);
    --website-icon-border: #666666;
    --website-review-border: var(--white-opacity);
    --show-more-btn: var(--gray-light);
    --agency-border: var(--white-opacity2);
    --toggle-buttons: var(--white-opacity3);
    --popup-bg: rgba(30, 30, 30, 0.8);
    --review-placeholder: var(--white-opacity4);
    --review-bg: var(--white-opacity2);
    --review-border: var(--white-opacity5);
    --footer-bg: var(--white-opacity6);
    --map-bg: #161616;
    --local-bg: #3d3d3d;
    --local-filter: none;
    --widget-btn-bg: var(--white-opacity2);
    --widget-btn-color: var(--white);
    --widget-btn-selected-bg: var(--white);
    --widget-btn-selected-color: #000;
    --code-bg: var(--gray-opacity);
    --copy-btn-color: #fff;
    --code-color: rgba(255, 255, 255, 0.8);
    --p-color: #f3f4fa;
    --svg-color-btn: #fff;
}