.container {
  position: relative;
  color: var(--inherit-color);
  z-index: 1;
  width: 100%;
  max-width: 1000px;
  background-color: var(--black-white);
  padding: 32px 24px;
  border-radius: 12px;
  display: flex;
  gap: 50px;
  justify-content: space-between;

  form {
    width: 100%;
    max-width: 450px;

    label {
      width: 100%;

      p {
        font-size: 18px;
        line-height: 21.6px;
        margin-bottom: 8px;
      }

      input,
      textarea {
        width: 100%;
        padding: 14px 16px;
        font-family: Lato;
        background-color: var(--review-bg);
        resize: none;
        margin-bottom: 8px;
        color: var(--inherit-color);

        &::placeholder {
          font-size: 16px;
          line-height: 16px;
          color: var(--review-placeholder);
        }
      }
    }

    textarea {
      min-height: 89px;
    }

    .your__score {
      width: 100%;
      justify-content: flex-start;
      margin-top: 4px;

      p {
        margin-right: 10px;
      }
    }

    button {
      width: 100%;
      cursor: pointer;
      background-color: var(--white-black);
      color: var(--black-white);
      padding: 14px 0;
      margin-top: 12px;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 24px;
    }
  }

  .main {
    width: 100%;
    max-width: 450px;

    .agency {
      width: 100%;
      align-items: flex-start;

      img {
        min-width: 124px;
        margin-right: 12px;
      }

      .right {
        .title {
          font-size: 20px;
          line-height: 24px;
        }

        .text {
          opacity: 0.8;
          font-weight: 400;
          font-size: 14px;
          line-height: 20.3px;
          margin: 4px 0 8px 0;
        }

        .contacts {
          font-size: 12px;
          line-height: 14.4px;
          display: flex;

          a {
            margin-right: 16px;
            color: var(--inherit-color);
          }

          .agency__webpage {
            color: var(--blue);

            &::after {
              content: url(../../icons/arrow.svg);
              margin-left: 4px;
            }
          }
        }
      }
    }

    .website {
      .website__header {
        margin-bottom: 8px;

        .rate {
          width: 24px;
          height: 22px;
          font-family: Inter;
          margin-right: 20px;

          span {
            color: var(--website-rate-count);
            position: relative;

            &::after {
              content: attr(data-rate);
              font-size: 10px;
              font-weight: 600;
              position: absolute;
              color: var(--green);
              top: -30%;
            }
          }
        }
      }

      .header__right {
        .right__name {
          width: fit-content;
          margin-bottom: 4px;
          img {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-right: 4px;
          }
        }
      }

      .website__webpage {
        color: var(--blue);
        font-size: 12px;
        line-height: 14.4px;

        &:hover {
          text-decoration: underline;
        }

        &::after {
          content: url(../../icons/arrow.svg);
          margin-left: 4px;
        }
      }
    }

    .count {
      margin-top: 20px;
      width: fit-content;

      p {
        margin-left: 8px;
        font-size: 13px;
        line-height: 13px;
        margin-top: 5px;

        span {
          font-weight: 400;
        }
      }
    }

    .widget {
      width: 100%;

      .title {
        margin-bottom: 16px;

        h1 {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          line-height: 20.3px;
          opacity: 0.8;
        }
      }

      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      .size {
        margin-bottom: 16px;

        .size__options {
          justify-content: flex-start;

          .size__option {
            padding: 8px 12px;
            background-color: var(--widget-btn-bg);
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 20.3px;
            margin-right: 8px;
            color: var(--widget-btn-color);

            &:hover {
              opacity: 0.8;
            }

            &.size__selected {
              background-color: var(--widget-btn-selected-bg);
              color: var(--widget-btn-selected-color);
            }
          }
        }
      }

      .theme {
        .theme__options {
          margin-top: 8px;
          justify-content: flex-start;

          .theme__option {
            padding: 8px 12px;
            background-color: var(--widget-btn-bg);
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 20.3px;
            margin-right: 8px;
            color: var(--widget-btn-color);

            div {
              width: 18px;
              height: 18px;
              background-color: var(--white);
              border-radius: 9px;
              border: 1px solid #c6c6c6;
              margin-right: 10px;

              &.theme__selected {
                background-color: #000;
              }
            }

            &.size__selected {
              background-color: var(--widget-btn-selected-bg);
              color: var(--widget-btn-selected-color);
            }
          }
        }
      }

      .widget__img {
        margin-top: 16px;
        color: var(--inherit-color);

        .img__wrapper {
          width: fit-content;
          position: relative;

          .widgetText1,
          .widgetText2 {
            position: absolute;
            translate: -50% -50%;
            font-weight: 700;
            font-family: Lato;
            text-align: center;
          }

          .widgetText1 {
            color: var(--gold);
            top: 48%;
            content: attr(data-country);
          }

          &.light .widgetText2 {
            color: var(--black);
          }

          .widgetText2 {
            top: 79%;
            content: attr(data-website);
          }

          &.wrapper__s .widgetText1,
          &.wrapper__s .widgetText2 {
            display: none;
          }

          &.wrapper__m .widgetText1 {
            left: 70%;
            font-size: 9.45px;
            line-height: 9.21px;
            width: 100px;
          }

          &.wrapper__l .widgetText1 {
            left: 70%;
            font-size: 15px;
            line-height: 15px;
            width: 170px;
          }

          &.wrapper__m .widgetText2 {
            left: 70%;
            font-size: 9.45px;
            line-height: 9.21px;
            width: 85px;
          }

          &.wrapper__m,
          &.wrapper__l {
            &.dark .widgetText2 {
              color: var(--white) !important;
            }

            &.light .widgetText2 {
              color: var(--black) !important;
            }
          }

          &.wrapper__l .widgetText2 {
            left: 70%;
            font-size: 15px;
            line-height: 15px;
            width: 130px;
          }

          .size__s {
            width: 88px;
          }

          .size__m {
            width: 185px;
          }

          .size__l {
            width: 350px;
          }
        }

        button {
          width: 100%;
          cursor: pointer;
          background-color: var(--white-black);
          color: var(--black-white);
          padding: 14px 0;
          margin-top: 12px;
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 8px;

          &:last-child {
            background-color: var(--code-bg);
            color: var(--code-color);
          }
        }

        .code__wrapper {
          .code {
            margin: 0;
            background-color: var(--code-bg);
            padding: 14px 10px;
            font-size: 14px;
            line-height: 16px;
            margin-top: 10px;
            overflow: hidden;
          }
        }
      }
    }
  }

  .reviews {
    width: 100%;
    margin-top: 16px;
    max-height: 306px;
    overflow-y: auto;
    margin-bottom: 24px;

    .item {
      width: 100%;
      padding: 12px;
      margin-bottom: 10px;
      border: 1px solid var(--review-border);

      &:last-child {
        margin-bottom: 0;
      }

      .header {
        width: 100%;
        align-items: flex-start;

        .person {
          color: var(--review-placeholder);

          .info {
            .date {
              font-size: 12px;
              line-height: 13px;
            }

            .name {
              color: var(--inherit-color);
              font-size: 16px;
            }
          }
        }
      }

      .text {
        color: var(--inherit-color);
        opacity: 0.8;
        font-size: 14px;
        font-weight: 400;
        margin-top: 6px;
      }
    }
  }
}

.contacts {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.link_site {
  color: #4a8ff3;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 8px;
}

.text {
  color: var(--inherit-color);
  opacity: 0.8;
  font-size: 14px;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  letter-spacing: -0.28px;
  margin-top: 6px;
}

.rate {
  margin-right: 20px;
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: var(--inherit-color);
  font-weight: 500;
  letter-spacing: -0.6px;
  padding-left: 4px;
  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 580px) {
  .container {
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    // padding: 0;
    .main .widget .widget__img .img__wrapper .size .size__l {
      width: 312px;
    }
  }
}
