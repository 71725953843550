.to__top {
    padding: 10px 14px;
    cursor: pointer;
    background-color: var(--inherit-color);
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 3;
    font-size: 18px;
    color: var(--red);
    rotate: 90deg;

    span {
        transform: scaleX(1.7);
    }
}

.not__found_wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .not__found {
        font-size: 24px;
        color: var(--red);
    }
}