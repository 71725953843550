.not-found {
    height: 100vh;
    color: var(--inherit-color);
    text-align: center;
    padding-top: 40vh;
}

.not-found__title {
    font-size: 32px;
    font-weight: 600;
}

.not-found__link {
    display: block;
    width: fit-content;
    margin: 28px auto 0;
    color: var(--inherit-color);
    padding: 4px;
    font-size: 16px;
}

.not-found__link:hover {
    text-decoration: underline;
}