.lang__dropdown {
  position: relative;
  margin-left: 8px;

  .select {
    border: 1px solid var(--border-opacity);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    padding: 14px 24px 10px 8px;
    position: relative;
    color: var(--inherit-color);
    cursor: pointer;
    transition: .3s;

    &::after {
      content: ">";
      font-family: none;
      font-size: 12px !important;
      position: absolute;
      transform: rotate(90deg) translate(0%, -50%) scaleY(2);
      font-size: 13px;
    }

    &:hover {
      border-color: var(--white-black);
    }
  }

  .options {
    width: 100%;
    height: fit-content;
    position: absolute;
    border: 1px solid var(--border-opacity);
    font-size: 15px;
    transform: translateY(2px) translateX(-50%);
    left: 50%;

    &.shown {
      display: none;
    }

    button {
      font-family: Lato;
      font-weight: 500;
      outline: none;
      border: none;
      width: 100%;
      text-align: center;
      padding: 12px 5px;
      color: var(--inherit-color);
      border-bottom: 1px solid var(--border-opacity);
      background-color: var(--black-white);
      cursor: pointer;
      transition: .3s;

      &:hover {
        opacity: .7;
      }

      &:last-child {
        border: none;
      }
    }
  }
}