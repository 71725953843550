footer {
    width: 100%;
    // height: 500px;
    position: relative;
    z-index: 2;
    padding: 62px 0 44px 0;
    background-color: var(--footer-bg);

    .footer__container {
        max-width: 1360px;
        width: 100%;
        margin: 0 auto;

        .top {
            width: 100%;
            align-items: flex-start;
        }

        .block__title {
            color: var(--inherit-color);
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 16px;
        }

        .countries {
            width: 100%;
            min-height: 67px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: flex-end !important;

            .country {
                padding: 8px 6px;
                font-size: 15px;
                line-height: 18px;
                border-radius: 8px;
                background-color: var(--country-disabled);
                color: var(--inherit-color);
                cursor: pointer;
                transition: .3s;
                font-size: 15px;
                margin: 0 0 2px 2px;
                div {
                    opacity: 0.4;
                }
                &.checked {
                    opacity: 1;
                    padding: 12px 6px;
                    background-color: var(--country-active);
                    div {
                        opacity: 1;
                    } 
                }

                &:hover {
                    opacity: 1;
                }
            }
        }

        .bottom {
            width: 100%;
            margin-top: 31px;

            .contacts {
                width: fit-content;
                flex-wrap: nowrap;

                p {
                    width: 93px;
                    color: var(--review-placeholder);
                    font-size: 14px;
                    line-height: 16.8px;
                    // text-wrap: nowrap;
                }

                a {
                    width: fit-content;
                    color: var(--inherit-color);
                    font-size: 15px;
                    line-height: 18px;
                    margin-left: 15px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .links {
            width: 100%;
            justify-content: flex-end;
            flex-wrap: nowrap;

            .link {
                font-size: 15px;
                line-height: 18px;
                color: var(--inherit-color);
                margin-left: 20px;
                background-color: transparent;

                &:hover {
                    text-decoration: underline;
                }
            }

            .button {
                margin-left: 20px;
                color: #fff;
                padding: 13px 46px 13px 16px;
                background-color: var(--add-site-link);
                position: relative;
                border: none;
                cursor: pointer;
                max-width: 360px;
                transition: .3s;

                &:hover {
                    opacity: .8;
                }

                &::after {
                    content: "+";
                    position: absolute;
                    font-size: 30px;
                    margin-left: 20px;
                    translate: -50% -50%;
                    top: 50%;
                }
            }
        }
    }
}


@media (max-width: 1408px) {
    footer {
        padding: 62px 24px 44px 24px;
    }
}

@media (max-width: 991px) {
    .bottom {
        flex-direction: column;

        .links {
            justify-content: center !important;
            order: 1;
            margin-bottom: 20px;
        }

        .contacts {
            order: 2;
        }
    }
}

@media (max-width: 710px) {
    footer {
        padding: 44px 16px;

        .top {
            display: block;

            .logo {
                margin-bottom: 24px;
            }
        }

        .bottom {
            flex-direction: column;

            p {
                order: 2;
            }

            .links {
                display: flex;
                flex-direction: column;
                justify-content: center !important;
                align-items: center !important;
                order: 1;
                flex-wrap: wrap;

                .link {
                    margin: 0 0 20px 0 !important;
                    order: 2;
                }

                .button {
                    width: 100%;
                    margin: 20px 0 20px 0 !important;
                    color: #fff;
                    order: 1;
                }
            }
        }
    }
}