.wrapper {
  width: 100%;
  max-width: 362px;
  min-width: 230px;

  .container {
    width: 100%;

    h2 {
      width: 100%;
      background-color: var(--black-white);
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      color: var(--inherit-color);
      padding: 20px;
      border-bottom: 1px solid var(--inherit-border);
      transition: .3s;
    }

    .agencies {
      transition: .3s;
      background-color: var(--black-white);
      width: 100%;
      min-height: 100vh;

      .agency {
        padding: 20px;
        color: var(--inherit-color);
        border-bottom: 1px solid var(--agency-border);

        p + a {
          font-size: 13px;
        }

        .open__reviews {
          cursor: pointer;
          height: fit-content;
          display: block;

          p {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            display: block;
            color: var(--inherit-color);
          }

          &:hover p {
            text-decoration: underline;
          }
        }

        &:last-child {
          border: none;
        }

        img {
          width: 200px;
        }

        .name {
          color: var(--inherit-color);
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;
        }

        .contacts {
          width: 100%;
          margin-top: 8px;
          line-height: 14.4px;
          font-size: 14px;
          flex-wrap: wrap;

          a {
            text-wrap: wrap;
            display: flex;
            align-items: center;
          }

          .number {
            margin-right: 8px;
            color: var(--inherit-color);

            &:hover {
              text-decoration: underline;
            }
          }

          .webpage {
            color: var(--blue);

            &:hover {
              text-decoration: underline;
            }

            &::after {
              content: url(../../icons/arrow.svg);
              margin-left: 4px;
            }
          }
        }

        .views {
          width: fit-content;
          border: 1px solid var(--border-opacity);
          padding: 6px;
          margin-top: 20px;
          position: relative;
          cursor: pointer;

          .pointer {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            z-index: 2;
          }

          p {
            font-size: 13px;
            line-height: 13px;
            font-weight: 400;
            color: var(--inherit-color);
            margin-left: 4px;
            text-align: center;
          }

          &:hover {
            border: 1px solid var(--inherit-color);
          }

          .stars {
            img {
              width: 18.6px;
            }
          }

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            transform: translateY(0) translateX(0);
            bottom: -50%;
            left: 50%;
            rotate: 45deg;
            border-radius: 1px;
            background-color: var(--white);
            opacity: 0;
            pointer-events: none;
          }

          &::after {
            content: attr(data-toolbar);
            position: absolute;
            transform: translateY(0) translateX(-50%);
            bottom: -100%;
            left: 50%;
            width: max-content;
            min-width: 77px;
            height: fit-content;
            text-align: center;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 600;
            font-family: Lato;
            line-height: 13.2px;
            background-color: var(--white);
            padding: 4px;
            opacity: 0;
            pointer-events: none;
            color: #000;
          }

          &:hover {
            &::before {
              opacity: 1;
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    .show__more_btn {
      margin: 16px 0 0 0;
      background-color: var(--show-more-btn);
      padding: 13px 16px;
      line-height: 18px;
      color: var(--white);
      font-size: 15px;
      font-family: Lato;
      font-weight: 500;
      cursor: pointer;
      transition: .3s;

      &:hover {
        opacity: .8;
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    max-width: none;

    .container {
      h2 {
        display: none;
      }
    }
  }
}

@media (max-width: 525px) {
  .wrapper {
    min-width: 100%;

    .container {
      .websites {
        .website {
          .info {
            flex-direction: column !important;
            align-items: flex-start;

            .main {
              margin-right: 0;
              margin-bottom: 12px;
            }

            .views {
              p {
                width: fit-content;
                color: var(--inherit-color);
                font-weight: 400;
                font-size: 13px;
                line-height: 13px;
                margin-left: 8px;
              }

              &::before {
                display: none;
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}