header {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;

    .header__container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        h1 {
            width: fit-content;
            margin: 47px auto 79px auto;
            color: var(--inherit-color);
            font-size: 48px;
            font-weight: 500;
            text-align: center;
            line-height: 57.6px;
            transition: .3s;
        }
    }
}

@media (max-width: 1408px) {
    header {
        padding: 0 24px;
    }
}

@media (max-width: 560px) {
    header {
        padding: 0 16px;
    }
}

@media (max-width: 400px) {
    header {
        padding: 0 8px;
    }
}