.wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000b4;
    z-index: 3;

    // &::after {
    //     transition: .2s;
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: var(--header-opacity);
    //     pointer-events: none;
    // }

    .container {
        width: 480px;
        position: absolute;
        z-index: 2;
        justify-content: space-between;
        color: var(--inherit-color);

        img {
            cursor: pointer;
        }

        form {
            width: 100%;
            margin: auto;
            border-radius: 12px;
            padding: 32px 24px 24px 24px;
            background-color: var(--black-white);

            .title {
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 12px;
            }
            
            .text {
                font-size: 14px;
                line-height: 16.8px;
                margin-bottom: 12px;
                text-align: center;
            }

            input {
                width: 100%;
                padding: 14px 16px;
                font-family: Lato;
                background-color: var(--review-bg);
                resize: none;
                margin-bottom: 12px;
                color: var(--inherit-color);

                &::placeholder {
                    font-size: 16px;
                    line-height: 16px;
                    color: var(--review-placeholder);
                }
            }

            .submit__btn {
                width: 100%;
                background-color: var(--white-black);
                color: var(--black-white);
                padding: 14px 0;
                font-size: 16px;
                line-height: 16px;
                cursor: pointer;
            }

            .restore__btn {                
                background-color: transparent;
                color: var(--inherit-color);
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                cursor: pointer;
                margin-top: 16px;
                margin-bottom: 8px;

                &:hover {
                    text-decoration: underline
                }
            }
            .nav__add_site_link {
                color: var(--inherit-color);
                border: 1px solid var(--border-opacity);
                padding: 4px;
                transition: .3s;
                &:hover {
                    border: 1px solid var(--inherit-color);
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .wrapper {
        .container {
            padding: 0 20px;
            width: 100%;
        }
    }
}