.menu {
    margin: 0 auto;
    width: 100%;

    max-width: 1360px;
    position: relative;
    z-index: 1;
    color: var(--white);
    font-size: 15px;

    img {
        cursor: pointer;
    }

    .header__nav {
        padding-top: 20px;
        line-height: 18px;

        .nav__rating_link {
            color: var(--inherit-color);
            margin-right: 8px;
            transition: .3s;
            cursor: pointer;
            min-width: fit-content;

            &:hover {
                text-decoration: underline;
            }
        }

        .nav__add_site_link {
            color: #fff;
            padding: 13px 46px 13px 16px;
            background-color: var(--add-site-link);
            position: relative;
            margin-left: 8px;
            font-size: 15px;
            line-height: 18px;
            cursor: pointer;
            transition: .3s;
            min-width: fit-content;

            &::after {
                content: "+";
                position: absolute;
                font-size: 30px;
                margin-left: 20px;
                translate: -85% -50%;
                top: 50%;
            }

            &:hover {
                opacity: .9;
            }
        }

        .nav__login_link {
            margin-left: 8px;
            min-width: 47px;
            width: 70px;
            max-width: 99px;
            height: 44px;
            background-color: var(--red);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: .3s;
            
            color: #fff;
            cursor: pointer;
            font-family: Lato;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            
            &:hover {
                opacity: .8;
            }
        }

        .mobile__login_link {
            background-image: url(../../icons/user.svg);
            background-size: auto;
            background-position: center;
            background-repeat: no-repeat;
            display: none;
        }
        
        .nav__logout_link {
            min-width: 47px;
            min-height: 44px;
            margin-left: 8px;
            transition: .3s;
            cursor: pointer;
            background-color: var(--add-site-link);
            background-image: url(../../icons/logout.svg);
            background-position: center;
            background-size: 35%;
            background-repeat: no-repeat;
            
            &:hover {
                opacity: .8;
            }
        }
    }
}

@media (max-width: 991px) {
    .menu {
        .header__nav {
            .nav__rating_link {
                display: none;
            }
        }
    }
}

@media (max-width: 870px) {
    .nav__add_site_link {
        display: none;
    }
}

@media (max-width: 751px) {
    .menu {
        .header__nav {

            .nav__change_lang {
                padding: 12.5px 24px 12.5px 6px;
            }
        }
    }
}

@media (max-width: 560px) {
    .menu {
        img {
            width: 118px;
        }
        .header__nav {
            .nav__login_link {
                margin-left: 0;
                display: none;
                width: 100%;
            }
            .mobile__login_link {
                display: flex;
            }
        }
    }
}