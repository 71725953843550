.toggle__theme {
  background-color: var(--theme-btn);
  padding: 2px;
  width: 68px;
  height: 44px;
  position: relative;
  margin-left: 8px;

  .theme__light {
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;

    &::before {
      content: url(../../icons/theme-light.svg);
      filter: var(--local-filter);
    }
  }
  .theme__dark {
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;

    &::before {
      content: url(../../icons/theme-dark.svg);
      filter: var(--local-filter);
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 40px;
    background-color: var(--white);
    top: 50%;
    left: var(--theme-bg);
    z-index: 0;
    transform: translate(-50%, -50%);
    transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  * {
    transition: 0.3s;
  }

  .theme__icon {
    width: 30px;
    height: 40px;
    padding: 10px 5px;
    z-index: 1;
    cursor: pointer;

    &:hover {
      path {
        fill: var(--yellow);
      }
    }
  }

  .sun {
    // background-color: var(--sun-bg);
    &::before {
      path {
        fill: var(--theme-sun);
      }
    }
  }

  .moon {
    // background-color: var(--moon-bg);

    &::before {
      path {
        fill: var(--theme-moon);
      }
    }
    
  }
}

@media (max-width: 450px) {
  .toggle__theme {
    width: 58px;

    &::before {
      content: "";
      position: absolute;
      width: 25px;
      height: 36px;
      background-color: var(--white);
      top: 50%;
      left: var(--theme-bg);
      z-index: 0;
      transform: translate(-50%, -50%);
      transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    }

    * {
      transition: 0.3s;
    }

    .theme__icon {
      width: 30px;
      height: 40px;
      padding: 10px 5px;
      z-index: 1;
      cursor: pointer;

      &:hover {
        path {
          fill: var(--yellow);
        }
      }
    }

    .sun {
      // background-color: var(--sun-bg);

      path {
        fill: var(--theme-sun);
      }
    }

    .moon {
      // background-color: var(--moon-bg);

      path {
        fill: var(--theme-moon);
      }
    }
  }
}