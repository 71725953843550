@import '../variables.scss';

.page.light, .loading__wrapper.light {
    --bg-header-add-site-btn: var(--black);
    --header-opacity: rgba(255, 255, 255, 0.90);
    --page-bg: url("../../images/light-bg.webp");
    --inherit-color: var(--black);
    --inherit-border: var(--gray-light2);
    --add-site-link: var(--black);
    --border-opacity: var(--black-opacity);
    --theme-btn: var(--black-opacity2);
    --theme-sun: var(--yellow);
    --theme-moon: rgba(11, 20, 33, 0.4);
    --theme-bg: 25%;
    --sun-bg: var(--white);
    --moon-bg: none;
    --country-disabled: #eeeef4;
    --country-active: var(--white);
    --main-bg: #f3f4fa;
    --black-white: var(--white);
    --white-black: var(--black);
    --website-rate-count: #7A7A7A;
    --website-icon-border: #242424;
    --website-review-border: var(--black-opacity2);
    --show-more-btn: var(--black);
    --agency-border: var(--gray-light2);
    --toggle-buttons: var(--black-opacity3);
    --popup-bg: rgba(174, 174, 174, 0.8);
    --review-placeholder: var(--black-opacity4);
    --review-bg: rgba(11, 20, 33, 0.08);
    --review-border: var(--gray-light4);
    --footer-bg: var(--gray-light5);
    --map-bg: rgba(11, 20, 33, 0.08);
    --local-bg: #f5f5f5;
    --local-filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7500%) hue-rotate(213deg) brightness(79%) contrast(114%);
    --widget-btn-bg: var(--black-opacity5);
    --widget-btn-color: #676767;
    --widget-btn-selected-color: var(--white);
    --widget-btn-selected-bg: var(--black);
    --code-bg: var(--gray-light6);
    --copy-btn-color: #2c2c2c;
    --code-color: #2c2c2c;
    --p-color: #39414c;
    --svg-color-btn: #17579E;
}