.countries {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  z-index: 2;

  .wrapper {
    width: 100%;
    align-items: flex-end !important;
    height: 67px;

    .country {
      padding: 8px 11.12px;
      border-radius: 8px;
      background-color: var(--country-disabled);
      color: var(--inherit-color);
      cursor: pointer;
      transition: 0.3s;
      font-size: 15px;
      opacity: 0.7;

      &.checked {
        background-color: var(--country-active);
        padding: 12px 11.12px;
        opacity: 1;
        // padding: 12px 11.12px;
      }

      &:hover {
        background-color: var(--country-active);
        // padding: 12px 11.12px;
        opacity: 1;
      }
    }
  }
}

.button_price {
  color: var(--inherit-color);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  height: 40px;
  padding: 0 12px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #000;
  background-color: var(--black-white);
  width: fit-content;
  float: right;

  &::before {
    content:  "€";
    width: 16px;
    height: 16px;
    background: var(--inherit-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
    color: var(--black-white);
  }
}

.icon_btn {
    fill: var(--svg-color-btn);
}

@media (max-width: 596px) {
    .button_price {
        margin-bottom: 20px;
        position: relative;
        z-index: 2;
      }
}

@media (max-width: 1248px) {
  .countries {
    .wrapper {
      display: none;
    }
  }
}
