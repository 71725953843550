@import './themes/dark.scss';
@import './themes/light.scss';
@import './fonts.scss';

.page {
    width: 100%;
    background-color: var(--main-bg);
    background-image: var(--page-bg);
    background-repeat: no-repeat;
    background-position: 85% 0;
    background-size: 100% auto;
    position: relative;
    transition: .3s;
}

.edit {
    width: 100%;
    z-index: 1;
    padding: 24px 0;
}

.meth {
    width: 100%;
    padding: 24px 0;
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
}

.meth a {
 color: var(--blue);
  text-decoration: underline;
}

.loading__wrapper {
    width: 100%;
    height: 100vh;
    background-color: var(--main-bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper {
    width: 100%;
    height: fit-content;
}

@media (max-width: 1200px) {
    .page {
        background-size: 150% auto;
    }
}

@media (max-width: 800px) {
    .page {
        background-size: 200% auto;
    }
}

@media (max-width: 600px) {
    .page {
        background-size: 250% auto;
    }
}

@media (max-width: 450px) {
    .page {
        background-size: 300% auto;
    }
}