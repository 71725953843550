:root {
  --white: #ffffff;
  --black: #0B1421;
  --gray: #404040;
  --gray-light: #767676;
  --gray-light2: #D1D1D1;
  --gray-light3: #7b7b7b;
  --gray-light4: #ececec;
  --gray-light5: #e2e3ec;
  --gray-light6: #ebebeb;
  --red: #BB0505;
  --gray-opacity: rgba(235, 235, 235, 0.12);
  --white-opacity: rgba(255, 255, 255, 0.24);
  --white-opacity2: rgba(255, 255, 255, 0.12);
  --white-opacity3: rgba(255, 255, 255, 0.6);
  --white-opacity4: rgba(255, 255, 255, 0.4);
  --white-opacity5: rgba(255, 255, 255, 0.1);
  --white-opacity6: rgba(255, 255, 255, 0.04);
  --white-opacity7: rgba(255, 255, 255, 0.25);
  --black-opacity: rgba(0, 0, 0, 0.1);
  --black-opacity2: rgba(11, 20, 33, 0.12);
  --black-opacity3: rgba(11, 20, 33, 0.6);
  --black-opacity4: rgba(11, 20, 33, 0.4);
  --black-opacity5: rgba(11, 20, 33, 0.08);
  --yellow: #F3C947;
  --green: #7DB843;
  --green-dark: #1CA182;
  --blue: #4A8FF3;
  --gold: #f1e482;
}