@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Semibold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Bold.ttf");
    font-weight: 700;
}