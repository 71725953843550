.wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000d0;
    display: flex;
    color: var(--inherit-color);
    padding: 12px;
    z-index: 4;

    .container {
        padding: 24px;
        width: 100%;
        max-width: 482px;
        background-color: var(--black-white);
        margin: auto;
        justify-content: space-between;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        position: relative;

        .close {
            position: absolute;
            top: 1%;
            right: 2%;
            font-size: 20px;
            color: var(--inherit-color);
            cursor: pointer;
        }

        .rect {
            width: 64px;
            height: 64px;
            border: 3px solid var(--inherit-color);
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 20px;
        }

        .p {
            opacity: .8;
            margin-bottom: 20px;
        }

        .h2 {
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 20px;
        }

        .accept {
            height: 48px;
            a, button {
                color: var(--inherit-color);
                width: 156px;
                height: 48px;
                margin: 0 5px;
                font-weight: 700;
                font-size: 16px;
                line-height: 19.2px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .3s;

                &:hover {
                    opacity: .8;
                }

                &:first-child {
                    background-color: var(--white-black);
                    color: var(--black-white);
                }

                &:last-child {
                    background-color: var(--border-opacity);
                    color: var(--white-black);
                }
            }
        }

        .cookies {
            margin-bottom: 20px;

            div {
                opacity: .8;
            }

            label {
                margin-left: 20px;
                position: relative;

                input {
                    width: 55px;
                    height: 28px;
                    background-color: var(--widget-btn-bg);
                    appearance: none;
                    cursor: pointer;

                    &::before {
                        content: "";
                        width: 24px;
                        height: 23px;
                        position: absolute;
                        z-index: 1;
                        translate: -100% -50%;
                        top: 44%;
                        left: 48%;
                        background-color: var(--gray-light);
                        transition: .3s;
                    }
                    
                    &.active::before {
                        left: 95%;
                        background-color: var(--green-dark);
                    }
                }

                span {
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    translate: -50% -60%;

                    &.on {
                        left: 25%;
                    }

                    &.off {
                        left: 75%;
                        color: var(--gray-light);
                    }
                }
            }
        }

        .toggle__wrapper {
            margin-bottom: 20px;
        }
    }
}