.wrapper {

    a {
        color: var(--blue);
        
        &:hover {
            text-decoration: underline;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 38.4px;
        margin: 40px 0 24px;
        
        &:first-child {
            font-size: 40px;
            line-height: 48px;
        }
    }

    li, p {
        margin: 10px 0;
    }

    ul, ol {
        margin: 20px;
    }

    p {
        font-size: 16px;
        line-height: 23.2px;
        color: var(--p-color);
    }

}

@media (max-width: 900px) {
    .wrapper {

        h2 {
            font-size: 32px;
            line-height: 38.4px;
            margin: 28px 0 16px 0;
            
            &:first-child {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }
}

@media (max-width: 450px) {
    .wrapper {
        h2 {
            font-size: 20px;
            line-height: 24px;
            margin: 20px 0 12px 0;
            
            &:first-child {
                margin: 45px 0 24px 0;
                font-size: 24px;
                line-height: 28.8px;
            }
        }
    }
}