.wrapper {
    width: 100%;
    color: var(--inherit-color);
    margin-top: 64px;
    padding-bottom: 20px;

    a {
        color: var(--blue);

        &:hover {
            text-decoration: underline;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 38.4px;
        margin: 40px 0 24px;
        
        &:first-child {
            font-size: 40px;
            line-height: 48px;
        }
    }

    li, p {
        margin: 10px 0;
    }

    ul, ol {
        margin: 20px;
    }

    p {
        font-size: 16px;
        line-height: 23.2px;
        color: var(--p-color);
    }

    // .title {
    //     font-size: 36px;
    //     line-height: 42.3px;
    //     margin: 16px 0;
    //     width: fit-content !important;
    // }

    // p {
    //     font-size: 16px;
    //     line-height: 23.2px;
    // }

    // .p__columns {
    //     columns: 2;
    // }

    // .block {
    //     align-items: flex-start;
    //     margin-bottom: 40px;

    //     p {
    //         width: 48%;
    //     }

    //     // img {
    //     //     width: 48%;
    //     //     margin: 0;
    //     // }

    //     div {
    //         width: 48%;
    //         p {
    //             width: 100%;
    //         }
    //     }

    //     .text__columns {
    //         width: 100%;
    //         columns: 2;
    //     }
    // }

    .block__center {
        align-items: center;
    }


    // img {
    //     width: 100%;
    //     margin: 24px 0;
    // }

}

@media (max-width: 900px) {
    .wrapper {
        .block {
            flex-direction: column;

            p {
                width: 100%;
                margin-bottom: 24px;
            }

            // img {
            //     width: 100%;
            // }

            .text__columns {
                columns: 1;
            }

            div {
                width: 100%;
                p {
                    width: 100%;
                }
            }

        }
        // .block__ordered {
        //     img {
        //         order: 1;
        //     }

        //     div {
        //         order: 2;
        //     }
        // }
    }
}