main {
    width: 100%;
    position: relative;
    z-index: 1;
    // background-color: var(--main-bg);
    transition: .3s;
    padding-bottom: 40px;
    margin-bottom: -40px;

    .main__container {
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        // transform: translateY(-300px);

        h1 {
            width: fit-content;
            margin: 50px auto;
            color: var(--inherit-color);
            font-size: 48px;
            font-weight: 500;
            text-align: center;
            line-height: 57.6px;
            transition: .3s;
        }

        .websites__agencies {
            align-items: flex-start;
        }
    }
}

@media (max-width: 1408px) {
    main {
        padding: 0 24px 80px 24px;
    }
}

@media (max-width: 1248px) {
    main {
        .main__container {
            h1 {
                font-size: 36px;
                line-height: 43.2px;
            }
        }
    }
}

@media (max-width: 900px) {
    main {
        .main__container {
            h1 {
                font-size: 36px;
                line-height: 43.2px;
            }
        }
    }
}

@media (max-width: 450px) {
    main {
        padding: 0 16px 80px 16px;

        .main__container {
            h1 {
                // margin: 58px auto 120px auto;
                font-size: 28px;
                line-height: 33.6px;
            }
        }

    }
}