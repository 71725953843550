.wrapper {
    width: 100%;
    height: 100vh;
    background-color: var(--black-opacity3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: fit-content;
        height: fit-content;
        padding: 50px 70px;
        background-color: var(--black-white);
        color: var(--inherit-color);
        font-size: 22px;
        line-height: 24px;
    }
}