.toggle__theme {
  background-color: var(--theme-btn);
  padding: 2px;
  width: 210px;
  height: 47px;
  position: relative;
  margin-left: 8px;

  &::before {
    content: "";
    position: absolute;
    width: 103px;
    height: 43px;
    background-color: var(--white);
    top: 51%;
    left: var(--theme-bg);
    z-index: 0;
    transform: translate(-50%, -50%);
    transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  * {
    transition: 0.3s;
  }

  div:first-child {
    p {
      color: var(--white-black);
    }
  }

  div:last-child {
    p {
      color: #000;
    }
  }

  div {
    width: 105px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      svg {

        path {
          fill: var(--yellow);
        }
      }
    }

    p {
      margin-left: 5px;
    }

    .theme__icon {
      width: 30px;
      height: 40px;
      padding: 11px 5px 9px 5px;
      z-index: 1;
      margin-left: 5px;
    }

    .sun {
      // background-color: var(--sun-bg);

      path {
        fill: var(--theme-sun);
      }
    }

    .moon {
      // background-color: var(--moon-bg);

      path {
        fill: var(--theme-moon);
      }
    }
  }
}

