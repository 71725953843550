@import "./styles/main.scss";

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
}

button,
input,
textarea {
    border: none;
    outline: none;
}

button {
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

a {
    text-decoration: none;
    color: var(--white);
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-start {
    max-width: 450px;
}

.price {
    padding: 12px;
    border-left: 10px solid #BB0505;
    background-color: var(--black-white);
} 