.wrapper {
    a {
        color: var(--blue);

        &:hover {
            text-decoration: underline;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 38.4px;
        margin: 40px 0 24px;
        
        &:first-child {
            font-size: 40px;
            line-height: 48px;
        }
    }

    li, p {
        margin: 10px 0;
    }

    ul, ol {
        margin: 20px;
    }

    p {
        font-size: 16px;
        line-height: 23.2px;
        color: var(--p-color);
    }
    
    .block1 {
        align-items: flex-start;
        width: 100%;
        margin-bottom: 64px;

        .text {
            width: 50%;
        }

        // img {
        //     width: 45%;
        //     margin-left: 40px;
        // }
    }

    .block2 {
        margin-top: 40px;
        align-items: flex-start;

        p {
            width: 48%;
        }

        div {
            &:last-child {
                margin-left: 24px;
            }
        }
    }

}

@media (max-width: 1250px) {
    .wrapper {

        h2 {
            font-size: 32px;
            line-height: 38.4px;
            margin: 28px 0 16px 0;
            
            &:first-child {
                margin: 48px 0 24px 0;
                font-size: 40px;
                line-height: 48px;
            }
        }
        
        .block1 {
            flex-direction: column;

            // img {
            //     width: 100%;
            //     margin-left: 0;
            //     margin-bottom: 24px;
            //     order: 1;
            // }

            .text {
                width: 100%;
                order: 2;
            }
        }

        .block2 {
            flex-direction: column;
            p {
                width: 100%;
            }
        }
    }
}

@media (max-width: 750px) {
    .wrapper {
        h2 {
            font-size: 20px;
            line-height: 24px;
            margin: 20px 0 12px 0;
            
            &:first-child {
                margin: 45px 0 24px 0;
                font-size: 24px;
                line-height: 28.8px;
            }
        }
    }
}