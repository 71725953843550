.dropdown {
    width: 100%;
    max-width: 400px;
    font-family: Inter;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    display: none;
    z-index: 1;

    .select {
        width: 100%;
        padding: 12px;
        justify-content: flex-start;
        background-color: var(--black-white);
        position: relative;
        color: var(--inherit-color);
        cursor: pointer;

        img {
            margin-right: 6px;
        }

        &::after {
            content: "<";
            position: absolute;
            transform: rotate(-90deg) translate(-50%, -50%) scaleY(1.6);
            top: 20%;
            right: 3%;
        }
    }

    .options {
        position: absolute;
        width: 100%;
        background-color: var(--black-white);
        margin-top: 2px;
        color: var(--inherit-color);
        max-height: 200px;
        overflow-y: scroll;
        
        .option {
            color: var(--inherit-color);
            padding: 12px;
            justify-content: flex-start;
            border-bottom: 1px solid var(--inherit-border);
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }

            img {
                margin-right: 6px;
            }
        }
    }
}

@media (max-width: 1248px) {
    .dropdown {
        display: block;
    }
}

@media (max-width: 450px) {
    .dropdown {
        display: block;

        width: 100%;
        max-width: 100%;

        .select {
            &::after {
                right: 2%;
            }
        }

        .options {
            max-height: 287px;

            .option {
                border-bottom: 1px solid var(--inherit-border);
            }
        }
    }
}