.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
    color: var(--inherit-color);
    position: relative;
    z-index: 1;
    background-color: var(--black-white);
    // border-radius: 12px;

    h1 {
        text-align: center;
        margin-bottom: 24px;
    }

    h2,
    h3 {
        margin-bottom: 12px;
    }

    p {
        opacity: .8;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 24px;

        a:hover {
            opacity: 0.6;
        }

        span {
            color: var(--blue);
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    ol {
        width: 100%;
        padding-left: 24px;
        margin-bottom: 24px;

        li {
            opacity: .8;
        }
    }

    .block {
        .text {
            width: 60%;
        }
    }

    form {
        max-width: 540px;
        width: 100%;
        margin: auto;
        border-radius: 12px;
        padding: 32px 24px 24px 24px;
        background-color: var(--black-white);

        input,
        textarea {
            width: 100%;
            padding: 14px 16px;
            font-family: Lato;
            background-color: var(--review-bg);
            resize: none;
            margin-bottom: 8px;
            color: var(--inherit-color);

            &::placeholder {
                font-size: 16px;
                line-height: 16px;
                color: var(--review-placeholder);
            }
        }

        .submit__btn {
            width: 100%;
            background-color: var(--white-black);
            color: var(--black-white);
            padding: 14px 0;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;
        }
    }
}

@media (max-width: 588px) {
    .container {
        width: 100%;
    }
}