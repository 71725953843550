.wrapper {
    width: 100%;

    .container {
        margin: 0 auto;
        background-color: var(--map-bg);
        position: relative;

        div {
            position: absolute;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            pointer-events: none;
            z-index: 1;
            translate: -50% -50%;
        }

        .greatbritain {
            top: 32%;
            left: 25%;
        }

        .france {
            top: 62%;
            left: 32%;
            font-size: 24px;
        }

        .italy {
            top: 71%;
            left: 48%;
            font-size: 24px;
        }

        .germany {
            top: 43%;
            left: 47%;
        }

        .spain {
            top: 87%;
            left: 14%;
        }

        .portugal {
            top: 90%;
            left: 3%;
            font-size: 18px;
        }

        .belgium {
            top: 42.5%;
            left: 37.3%;
            font-size: 13px;
        }

        .netherlands {
            top: 35.7%;
            left: 39%;
            font-size: 16px;
        }

        .switzerland {
            top: 61.5%;
            left: 43.5%;
            font-size: 17px;
        }

        .austria {
            top: 57%;
            left: 58%;
            font-size: 19px;
        }

        .czechia {
            top: 47%;
            left: 58.5%;
            font-size: 19px;
        }

        .poland {
            top: 35%;
            left: 65%;
            font-size: 24px;
        }

        .slovakia {
            top: 50.5%;
            left: 67%;
            font-size: 18px;
        }

        .hungary {
            top: 59%;
            left: 67%;
            font-size: 22px;
        }

        .croatia {
            top: 66.5%;
            left: 62.5%;
            font-size: 16px;
        }

        .serbia {
            top: 72%;
            left: 72%;
            font-size: 20px;
        }

        .albania {
            top: 88%;
            left: 71.8%;
            font-size: 13px;
        }

        .romania {
            top: 62%;
            left: 80%;
            font-size: 24px;
        }

        .bulgaria {
            top: 76.5%;
            left: 82%;
            font-size: 24px;
        }

        .greece {
            top: 93%;
            left: 76%;
            font-size: 20px;
        }

        .turkiye {
            font-size: 15px;
            top: 90%;
            left: 93%;
            font-size: 24px;
        }

        .luxembourg {
            top: 53.95%;
            left: 39%;
            font-size: 15px;
        }

        .monaco {
            top: 83.3%;
            left: 40.3%;
            font-size: 15px;
        }

        svg {
            width: fit-content;
            object-fit: contain;
            overflow: visible;
            path,
            [data-map="rect"] {
                border: none;
                outline: none;
            }
        }
    }

    .info {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 24px;
        gap: 16px 22px;

        .green,
        .light__green,
        .blue,
        .orange,
        .red {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 18.2px;
            font-weight: 600;
            color: var(--inherit-color);

            &::before {
                content: "";
                display: block;
                min-width: 18px;
                height: 18px;
                margin-right: 12px;
            }
        }

        .green {
            &::before {
                background-color: #3B6F06;
            }
        }

        .light__green {
            &::before {
                background-color: #85B844;
            }
        }

        .blue {
            &::before {
                background-color: #13438b;
            }
        }

        .orange {
            &::before {
                background-color: #BE7115;
            }
        }

        .red {
            &::before {
                background-color: #7B0D0D;
            }
        }
    }
}

.country-tooltip {
    background-color: #000;
}

[data-map="text"] {
    pointer-events: none;
}

[data-map="border"] {
    stroke-opacity: 0.2;
    stroke-width: 0.8px;
}

[data-map="country"]:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 50px rgb(255, 255, 255));
    stroke-width: 3px;
    stroke-opacity: 1;
    z-index: 99999;
}

[data-map="country"]:hover~[data-map="border"] {
    z-index: 99999;
    stroke-width: 3px;
    stroke-opacity: 1;
}

[data-map="rect"]:hover {
    filter: drop-shadow(0px 0px 50px rgb(255, 255, 255));
    cursor: pointer;
    z-index: 99999;
    stroke-width: 3px;
    stroke-opacity: 1;
}

@media (max-width: 1300px) {
    .wrapper {
        .container {
            .luxembourg {
                font-size: 13px;
            }

            .monaco {
                font-size: 13px;
            }

            .portugal {
                font-size: 15px;
            }

            .netherlands {
                font-size: 13px;
            }

            .albania {
                font-size: 11px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .wrapper {
        .container {
            .luxembourg {
                font-size: 10px;
            }

            .monaco {
                font-size: 10px;
            }
        }
    }
}

@media (max-width: 1100px) {
    .wrapper {
        .container {
            .portugal {
                font-size: 12px;
            }

            .netherlands {
                font-size: 11px;
            }

            .italy {
                top: 70%;
                font-size: 15px;
            }

            .greatbritain {
                font-size: 14px;
            }

            .turkiye {
                font-size: 15px;
            }

            .austria {
                font-size: 14px;
            }

            .albania {
                font-size: 9px;
            }

            .croatia {
                font-size: 10px;
            }

            .romania {
                font-size: 17px;
            }

            .bulgaria {
                font-size: 17px;
            }

            .serbia {
                font-size: 15px;
            }

            .hungary {
                font-size: 17px;
            }

            .slovakia {
                font-size: 13px;
            }

            .greece {
                font-size: 15px;
            }

            .belgium {
                font-size: 9px;
            }

            .monaco {
                top: 81.8%;
            }

            .switzerland {
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 940px) {
    .wrapper {
        .container {
            .luxembourg {
                top: 52.3%;
                font-size: 11px;
            }

            .monaco {
                top: 81.3%;
                font-size: 11px;
            }

            .albania {
                font-size: 7px;
            }

            .greece {
                font-size: 10px;
            }

            .serbia {
                font-size: 10px;
            }

            .czechia {
                font-size: 10px;
            }

            .poland {
                font-size: 12px;
            }

            .germany {
                font-size: 12px;
            }

            .spain {
                font-size: 14px;
            }

            .slovakia {
                font-size: 9px;
            }

            .portugal {
                font-size: 10px;
            }

            .croatia {
                font-size: 8px;
            }

            .netherlands {
                font-size: 9px;
            }

            .greatbritain {
                font-size: 10px;
            }

            .france {
                font-size: 14px;
            }

            .hungary {
                font-size: 12px;
            }

            .austria {
                font-size: 10px;
            }

            .switzerland {
                top: 61%;
                font-size: 9px;
            }

            .croatia {
                top: 66%;
                font-size: 8px;
            }

            .italy {
                font-size: 10px;
            }

            .bulgaria {
                font-size: 10px;
            }

            .romania {
                font-size: 12px
            }

            .turkiye {
                font-size: 12px
            }
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        .container {
            .luxembourg {
                top: 52.3%;
                font-size: 9px;
            }

            .monaco {
                top: 81.3%;
                font-size: 9px;
            }

            .albania {
                font-size: 7px;
            }

            .greece {
                font-size: 10px;
            }

            .serbia {
                font-size: 10px;
            }

            .czechia {
                font-size: 10px;
            }

            .poland {
                font-size: 12px;
            }

            .germany {
                font-size: 12px;
            }

            .spain {
                font-size: 14px;
            }

            .slovakia {
                font-size: 9px;
            }

            .portugal {
                font-size: 10px;
            }

            .croatia {
                font-size: 8px;
            }

            .netherlands {
                font-size: 9px;
            }

            .greatbritain {
                font-size: 10px;
            }

            .france {
                font-size: 14px;
            }

            .hungary {
                font-size: 12px;
            }

            .austria {
                font-size: 10px;
            }

            .switzerland {
                top: 61%;
                font-size: 9px;
            }

            .croatia {
                top: 66%;
                font-size: 8px;
            }

            .italy {
                font-size: 10px;
            }

            .bulgaria {
                font-size: 10px;
            }

            .romania {
                font-size: 12px
            }

            .turkiye {
                font-size: 12px
            }
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        .container {
            .luxembourg {
                top: 52.5%;
                font-size: 6px;
            }

            .monaco {
                top: 81.5%;
                font-size: 6px;
            }
        }
    }
}

@media (max-width: 900px) {
    .wrapper {
        .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}